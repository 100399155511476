import { RouteRecordRaw } from "vue-router";
import loginGard from "./login_gard";
import { patientGard } from "./patient_gard";

import questionnaire_routes from "./questionnaire_routes";

const series_routes: Array<RouteRecordRaw> = [
  {
    path: "/series/list",
    name: "series_list",
    redirect: "/organization",
  },
  {
    path: "/series/:series_id",
    component: () => import("../views/series/SeriesHome.vue"),
    children: [
      {
        path: "",
        component: () => import("../views/series/SeriesBase.vue"),
        beforeEnter: loginGard,
      },
      {
        path: "descriptive",
        name: "descriptive_summary",
        component: () => import("../views/series/descriptive/Descriptive.vue"),
        beforeEnter: loginGard,
      },
      {
        name: "data_manager",
        path: "data_manager",
        beforeEnter: loginGard,
        component: () => import("../views/series/DataManager.vue"),
      },
      {
        name: "settings",
        path: "settings",
        beforeEnter: loginGard,
        meta: {
          requiresAuth: false,
        },
        component: () => import("../views/series/SerieSettings.vue"),
        children: [
          {
            path: "",
            name: "general_info_settings",
            component: () => import("../views/series/settings/GeneralSettings.vue"),
          },
          {
            path: "identity_variables",
            name: "identity_variables",
            component: () => import("../views/series/settings/IdentityVariables.vue"),
          },
          {
            path: "e-CRF",
            name: "e-CRF",
            component: () => import("../views/series/settings/Ecrf.vue"),
          },
          {
            path: "record_identifier",
            name: "record_identifier",
            component: () => import("../views/series/settings/RecordIdentifier.vue"),
          },
          {
            path: "user_information",
            name: "user_information",
            component: () => import("../views/series/settings/UserInformation.vue"),
          },
          {
            path: "monitoring",
            name: "monitoring",
            component: () => import("../views/series/settings/Monitoring.vue"),
          },
          {
            path: "study",
            name: "study",
            component: () => import("../views/series/settings/Study.vue"),
          },
          {
            path: "files",
            name: "files",
            component: () => import("../views/series/settings/Files.vue"),
          },
        ],
      },
      {
        name: "sharing",
        path: "sharing",
        beforeEnter: loginGard,
        component: () => import("../views/series/SeriesSharing.vue"),
      },
      {
        name: "sharingEdit",
        path: "sharing/edit/:user_id",
        beforeEnter: loginGard,
        component: () => import("../views/series/SeriesSharingEdit.vue"),
      },
      {
        path: "samplesize/pmcf",
        name: "samplesize",
        component: () => import("../views/samplesize/DmSampleSize.vue"),
      },
      {
        path: "cleaner",
        name: "data-cleaner",
        beforeEnter: loginGard,
        component: () => import("../views/series/cleaner/CleanerView.vue"),
      },
      {
        name: "survival",
        path: "survival/:var_id",
        component: () => import("../views/series/survival/SurvivalAnalysis.vue"),
      },
      {
        path: "multivariate",
        name: "multivariate_list",
        beforeEnter: loginGard,
        component: () => import("../views/series/multivariate/List.vue"),
      },
      {
        path: "multivariate/:model_id",
        name: "multivariate_analysis",
        beforeEnter: loginGard,
        component: () => import("../views/series/multivariate/MultivariatelAnalysis.vue"),
      },
      {
        path: "multivariate/:model_id/:version",
        name: "multivariate_analysis_version",
        beforeEnter: loginGard,
        component: () => import("../views/series/multivariate/MultivariatelAnalysis.vue"),
      },
      {
        path: "query_list",
        name: "query_list",
        beforeEnter: loginGard,
        component: () => import("../views/series/query/QueryList.vue"),
      },
      {
        path: "report/:report_id",
        name: "report",
        beforeEnter: loginGard,
        component: () => import("../views/series/report/ReportsPage.vue"),
      },
      {
        path: "testvars",
        name: "testvars",
        beforeEnter: loginGard,
        component: () => import("../views/series/comparevars/CompareVarsPage.vue"),
      },
      {
        path: "testvars/:model_id",
        name: "testvars_model",
        beforeEnter: loginGard,
        component: () => import("../views/series/comparevars/CompareVarsPage.vue"),
      },
      {
        path: "testvariable/:var_id",
        name: "testvars_variable",
        beforeEnter: loginGard,
        component: () => import("../views/series/comparevars/CompareVarsPage.vue"),
      },
      {
        path: "comparegroups",
        name: "comparegroups",
        beforeEnter: loginGard,
        component: () => import("../views/series/comparegroups/CompareGroupsPage.vue"),
      },
      {
        path: "comparegroups/:model_id",
        name: "comparegroups_model",
        beforeEnter: loginGard,
        component: () => import("../views/series/comparegroups/CompareGroupsPage.vue"),
      },
      {
        path: "collab_request",
        name: "CollabRequest",
        beforeEnter: loginGard,
        component: () => import("../views/payment/CollabRequest.vue"),
      },
      {
        path: "limit_warning",
        name: "seriesLimitWarning",
        component: () => import("../views/series/LimitWarning.vue"),
      },
      {
        name: "exportCRF",
        path: "exportCRF",
        component: () => import("../views/series/ExportCRF.vue"),
      },
      {
        name: "export",
        path: "export",
        component: () => import("../views/series/patient/export/ExportData.vue"),
      },
      {
        path: "category/:cat_id",
        name: "category",
        beforeEnter: loginGard,
        component: () => import("../views/series/variable/CategoryManager.vue"),
      },
      {
        path: "variable/:var_id",
        name: "variable",
        beforeEnter: loginGard,
        component: () => import("../views/series/variable/VariableManager.vue"),
      },
      {
        path: "automation_list",
        name: "automation_list",
        beforeEnter: loginGard,
        component: () => import("../views/series/automation/AutomationList.vue"),
      },
      {
        path: "automation_events",
        name: "automation_events",
        beforeEnter: loginGard,
        component: () => import("../views/series/variable/CategoryManager.vue"),
      },
      {
        path: "not_allowed",
        name: "NotAllowedInSerie",
        component: () => import("../views/NotAllowed.vue"),
      },
      ...questionnaire_routes,
    ],

    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/series/:series_id/automl",
    name: "automl_home",
    redirect: { name: "automl_projects" },
    beforeEnter: loginGard,
    meta: {
      fixedNav: true,
    },
    component: () => import("../views/series/automl/AutomlHome.vue"),
    children: [
      {
        path: "projects",
        beforeEnter: loginGard,
        children: [
          {
            path: "",
            name: "automl_projects",
            beforeEnter: loginGard,
            component: () => import("../views/series/automl/Projects.vue"),
          },
          {
            path: ":project_id",
            name: "automl_project",
            redirect: { name: "automl_dashboard" },
            beforeEnter: loginGard,
            component: () => import("../views/series/automl/ProjectHome.vue"),
            children: [
              {
                path: "dashboard",
                name: "automl_dashboard",
                beforeEnter: loginGard,
                component: () => import("../views/series/automl/Dashboard.vue"),
              },
              {
                path: "checklist",
                name: "automl_checklist",
                beforeEnter: loginGard,
                component: () => import("../views/series/automl/Checklist.vue"),
              },
              {
                path: "datasets",
                name: "automl_datasets",
                beforeEnter: loginGard,
                children: [
                  {
                    path: "",
                    name: "automl_dataset_list",
                    beforeEnter: loginGard,
                    component: () => import("../views/series/automl/DatasetList.vue"),
                  },
                  {
                    path: "new/:clonedDataset?",
                    name: "automl_dataset_new",
                    beforeEnter: loginGard,
                    component: () => import("../views/series/automl/DatasetNew.vue"),
                  },
                  {
                    path: ":dataset_id",
                    name: "automl_dataset",
                    beforeEnter: loginGard,
                    component: () => import("../views/series/automl/Dataset.vue"),
                  },
                ],
              },
              {
                path: "experiment",
                beforeEnter: loginGard,
                children: [
                  {
                    path: "",
                    name: "automl_experiments",
                    beforeEnter: loginGard,
                    component: () => import("../views/series/automl/Experiments.vue"),
                  },
                  {
                    path: "new",
                    name: "automl_experiment_new",
                    beforeEnter: loginGard,
                    component: () => import("../views/series/automl/ExperimentNew.vue"),
                  },
                  {
                    path: ":experiment_id?/models",
                    beforeEnter: loginGard,
                    children: [
                      {
                        path: "",
                        name: "automl_models_overview",
                        beforeEnter: loginGard,
                        component: () => import("../views/series/automl/ModelsOverview.vue"),
                      },
                      {
                        path: ":model_family",
                        name: "automl_models_detail",
                        beforeEnter: loginGard,
                        component: () => import("../views/series/automl/ModelDetail.vue"),
                      },
                    ],
                  },
                ],
              },
              {
                path: "predictions/:experiment_id?/:model?",
                name: "automl_predictions",
                beforeEnter: loginGard,
                component: () => import("../views/series/automl/Predictions.vue"),
              },
              {
                path: "calibration/:experiment_id?/:model?",
                name: "automl_calibration",
                beforeEnter: loginGard,
                component: () => import("../views/series/automl/Calibration.vue"),
              },
              {
                path: "publications",
                name: "automl_publications",
                beforeEnter: loginGard,
                component: () => import("../views/series/automl/Publications.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "projects/new",
        name: "automl_new_project",
        beforeEnter: loginGard,
        component: () => import("../views/series/automl/ProjectNew.vue"),
      },
    ],
  },
  {
    path: "/series/:series_id/patient",
    name: "PatientHome",
    beforeEnter: loginGard,
    redirect: { name: "NewPatient" },
    component: () => import("../views/series/patient/PatientHome.vue"),
    children: [
      {
        path: ":patient_id",
        beforeEnter: async (to, from) => {
          await loginGard(to);
          await patientGard(to, from);
        },
        name: "PatientView",
        component: () => import("../views/series/patient/PatientView.vue"),
        children: [
          {
            path: "",
            beforeEnter: loginGard,
            name: "PatientForm",
            meta: {
              fixedNav: true,
            },
            component: () => import("../views/series/patient/PatientFormView.vue"),
          },
          {
            path: "excluded",
            beforeEnter: loginGard,
            name: "PatientExcludedView",
            meta: {
              fixedNav: true,
            },
            component: () => import("../views/series/patient/PatientExcludedView.vue"),
          },
          {
            path: "questionnaire",
            beforeEnter: loginGard,
            name: "PatientQuestionnaireView",
            meta: {
              fixedNav: true,
            },
            component: () => import("../views/series/patient/PatientQuestionnaireView.vue"),
          },
        ],
      },
      {
        path: "new",
        beforeEnter: loginGard,
        name: "NewPatient",
        meta: {
          fixedNav: true,
        },
        component: () => import("../views/series/patient/NewPatient.vue"),
      },
      {
        path: "notallowed",
        beforeEnter: loginGard,
        name: "NotAllowedInPatient",
        meta: {
          fixedNav: true,
        },
        component: () => import("../views/series/patient/NotAllowedPatient.vue"),
      },
    ],
  },
];

export default series_routes;
